<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-14 15:16:09
-->
<!-- 管理员端---点击员工姓名进入考试结果页面---试卷详情 -->
<template>
  <div>
    <el-card shadow='never'>
      <div slot="header">
        <div style="margin-bottom:16px;" class="page-top">
          <div class="return-title" style="display:flex;">
            <div class="back-btn" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
            <span class="division">|</span>
            <div class="back-title">试卷详情</div>
          </div>
          <div>
            <el-button @click="_print">打印</el-button>
          </div>
        </div>
      </div>
      <!-- 批阅试卷内容 -->
      <div class="paper-content">
        <el-form class="paper-form-content" label-width="56px">
          <div class="paper-left">
            <com-title title="基本情况" />
            <!-- 基本情况 -->
            <div class="base-paper">
              <div class="base-left">
                <el-form-item label="考试时长">{{basicInfo.examTime}}分钟</el-form-item>
                <el-form-item label="全部题目">{{basicInfo.questionNum}}</el-form-item>
                <el-form-item label="正确题目">{{basicInfo.trueNUm}}</el-form-item>
                <el-form-item label="错误题目">{{basicInfo.wrongNum}}</el-form-item>
                <el-form-item label="已批阅">{{basicInfo.reviewedNum}}</el-form-item>
                <el-form-item label="待批阅">{{basicInfo.unratedNum}}</el-form-item>
              </div>
              <div class="base-right">
                <el-progress type="circle" :show-text="false" :percentage="percentageScore" :width="110" :stroke-width="12" stroke-linecap="round"/>
                <div class="textCenter">
                  <div style="white-space: nowrap;">{{basicInfo.testerTotalScore}}分</div>
                  <span>总分{{basicInfo.sumScore}}分</span>
                </div>
              </div>
            </div>
            <!-- 答题卡 -->
            <div class="answer-sheets">
              <div class="answer-title">
                <com-title title="基本情况"/>
                <span>可点击序号进行快速定位</span>
              </div>
              <div class="answer-status">
                <div>
                  <span id="dot-box" class="blue"></span>
                  <span>正确</span>
                </div>
                <div>
                  <span id="dot-box" class="red"></span>
                  <span>错误</span>
                </div>
                <div>
                  <span id="dot-box" class="orange"></span>
                  <span>未批阅</span>
                </div>
              </div>
              <!-- 题目序号--开始 -->
              <div class="quick-position">
                <div v-for="(questionsGroup,questionIndex) in questionsGroupList" :key="questionIndex" class="quick-position-content">
                  <div v-if="questionsGroup.groupName" class="position-group-title">{{questionsGroup.groupName}}</div>
                  <div class="position-group-content">
                    <div
                      v-for="(topicItem, topicIndex) in questionsGroup?.questionInfo"
                      :key="topicIndex"
                      :class="topicItem.mark==topicItem.score?'blue':(
                      topicItem.mark===null||topicItem.mark===''||topicItem.mark===undefined?'orange':
                      topicItem.mark!==null&&topicItem.mark!==''&&topicItem.mark!==undefined&&Number(topicItem.mark||0)<Number(topicItem.score||0)?'red':'')"
                      id="position-number"
                      @click="_quickPositionBtn(questionIndex,topicIndex)">
                      {{topicIndex+1}}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 题目序号--结束 -->
            </div>
          </div>
          <!-- 试卷信息 -->
          <div class="paper-right print printable-content"  ref="printable">
            <!-- 试卷题目 -->
            <div class="paper-topic-title">
              试卷题目
            </div>
            <!-- 试题类型 -->
            <div class="question-content" id="dynamic-table-page">
              <div>
                <div v-for="(questionsGroup,groupIndex) in questionsGroupList" :key="groupIndex">
                  <!--题目分组名称--开始  -->
                  <div v-if="questionsGroup.groupName">
                    <div style="display:flex; justify-content:space-between;margin:16px 0px;">
                        <com-title :title="questionsGroup.groupName" />
                    </div>
                  </div>
                  <!--题目分组名称--结束  -->
                  <div
                    class="question-item"
                    v-for="(item, topicIndex) in questionsGroup?.questionInfo||[]"
                    :id="`topicLink${questionsGroup?.groupIndex}${topicIndex}`"
                    :key="topicIndex">
                    <div class="question-item-title">
                      <div class="title-left">
                        <span>{{ topicIndex+1 }}.</span>
                        <span class="type">「{{ getTypeValue(item.questionType) }}」</span>
                        <span>{{item.title}}</span>
                        <span>（{{item.score}}分）</span>
                      </div>
                      <!-- 单选、多选、判断题-自动得分--样式 -start -->
                      <div class="title-right">
                        <span>得分</span>
                        <span>{{item.testerMark}}</span>分
                        <span v-if="item.correctStatus==1&&item.questionType!=='GAP_FILLING_TYPE'&&item.questionType!=='ESSAY_QUESTION_TYPE'"><img src="@/assets/img/icon_Right.png" alt=""></span>
                        <span v-else-if="item.questionType!=='GAP_FILLING_TYPE'&&item.questionType!=='ESSAY_QUESTION_TYPE'"><img src="@/assets/img/icon_Wrong.png" alt=""></span>
                      </div>
                      <!-- 单选、多选、判断题-自动得分--样式 -end-->
                      <!-- 填空、问答--样式 -start -->
                      <!-- <div class="title-right" v-else>
                        <span>得分</span>
                        <span>
                          <el-input
                            style="width:88px;"
                            v-model="item.mark"
                            placeholder="请打分"
                            type="number"
                            @input="_inputTopicMark(item)">
                          <i slot="suffix" style="font-style:normal;color:#1A1A1A;line-height:32px;">分</i>
                        </el-input></span>
                      </div> -->
                      <!-- 填空、问答--样式 -end-->
                    </div>
                    <!-- 题目说明 -->
                    <div class="question-item-explain">题目说明：{{item.content}}</div>
                    <!-- 题目说明---上传图片 开始-->
                    <div v-if="item?.contentUrl!==''&&item?.contentUrl.length!==0&&item?.contentUrl[0]!==''" style="margin-bottom:16px;">
                      <el-image v-for="(item,index) in item?.contentUrl" :key="index" :src="item" style="margin-right:10px;width:100px;height:100px;" :preview-src-list="[item]"></el-image>
                    </div>
                    <!-- 题目说明---上传图片--结束 -->
                    <!-- 单项选择、判断题--开始 -->
                    <div v-if="item.questionType === 'SINGLE_CHOICE_TYPE'||item.questionType === 'JUDGMENT_TYPE'">
                      <el-radio-group class="radio-box" v-model="item.testerAnswer">
                        <el-radio
                          style="display:flex;"
                          v-for="(ele, index) in item?.options"
                          :key="index"
                          :label="`选项${index + 1}`"
                          :class="item.answer===`选项${index + 1}`?'radio-background prohibit-click':
                          item.testerAnswer&&item.answer!==item.testerAnswer&&item.testerAnswer===`选项${index + 1}`?'radio-background-error prohibit-click':'prohibit-click'"
                          >
                          <div class="radio-item-li">
                            <span>{{ ele.optionName }}</span>
                            <span v-if="item.answer===`选项${index + 1}`">正确答案</span>
                            <span v-else-if="item.testerAnswer&&item.answer!==item.testerAnswer&&item.testerAnswer===`选项${index + 1}`">错误答案</span>
                            <span v-else></span>
                          </div>
                          </el-radio>
                      </el-radio-group>
                    </div>
                    <!-- 单项选择、判断题--结束 -->
                    <!-- 多项选择-开始 -->
                    <div v-if="item.questionType ==='MULTIPLE_CHOICE_TYPE'">
                    <el-checkbox-group class="check-box" v-model="item.testerAnswerCheck">
                      <el-checkbox
                        v-for="(ele, index) in item.options"
                        :key="index"
                        :label="`选项${index + 1}`"
                        :class="item.answerCheck.indexOf(`选项${index + 1}`)!==-1?'check-background prohibit-click':
                        item.testerAnswerCheck&&item.answerCheck!==item.testerAnswerCheck&&item.testerAnswerCheck.indexOf(`选项${index + 1}`)!==-1?'radio-background-error prohibit-click':'prohibit-click'"
                        >
                        <div class="check-item-li">
                          <span>{{ ele.optionName }}</span>
                          <span v-if="item.answerCheck.indexOf(`选项${index + 1}`)!==-1">正确答案</span>
                          <span v-else-if="item.testerAnswerCheck&&item.answerCheck!==item.testerAnswerCheck&&item.testerAnswerCheck.indexOf(`选项${index + 1}`)!==-1">错误答案</span>
                          <span v-else></span>
                        </div>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <!-- 多项选择-结束 -->
                    <!-- 填空题 -开始-->
                    <div v-if="item.questionType === 'GAP_FILLING_TYPE'">
                      <div v-for="(fillItem,fillIndex) in item?.fillAnswer" :key="fillIndex">
                        <div class="input-box prohibit-click">
                          <span>填空{{fillIndex+1}}</span>
                          <el-input v-model="fillItem.answer" placeholder="请输入"></el-input>
                        </div>
                      </div>
                    </div>
                    <!-- 填空题 -结束-->
                    <!-- 问答题 -->
                    <div v-if="item.questionType==='ESSAY_QUESTION_TYPE'" class="quiz-question-item">
                      <el-row>
                        <el-form-item label="" class="prohibit-click">
                          <el-input v-model="item.testerAnswer" type="textarea" autosize></el-input>
                        </el-form-item>
                      </el-row>
                    </div>
                    <div class="answer-analyse">
                      <span>答案解析</span>
                      <span>{{item.analysis}}</span>
                    </div>
                    <!-- 答案解析---上传图片 开始-->
                    <div v-if="item.analysisUrl!==''&&item.analysisUrl.length!==0&&item?.analysisUrl[0]!==''">
                      <el-image v-for="(item,index) in item.analysisUrl" :key="index" :src="item" style="margin-right:10px; width:100px;height:100px;" :preview-src-list="[item]"></el-image>
                    </div>
                    <!-- 答案解析---上传图片 结束-->
                    <!-- 其他说明--开始 -->
                    <div class="answer-analyse" v-if="item.otherInstructions">
                      <span>其他说明</span>
                      <span>{{item.otherInstructions}}</span>
                    </div>
                    <!-- 其他说明--结束 -->
                  </div>
                </div>
              </div>
              <!-- 空白 -->
              <div class="paper-topic-blank">
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </el-card>
    <iframe width="0" height="0" frameborder="0" id="oprs-iframe"></iframe>
  </div>
</template>
<script>
import comTitle from '@/components/comTitle'
import { getStudentExamDetailApi, getExamGradingApi } from '@/api/examApi'
import { printPage } from '@/utils/htmlToPdf'
export default {
  components: { comTitle },
  data () {
    return {
      basicInfo: {}, // 基本信息
      questionsGroupList: [], // 题目列表
      percentageScore: 0,
      allTopicListCopy: []// 所有题目
    }
  },
  created () {
    if (this.$route.query.examInfoId && this.$route.query.employeeId) {
      this._getExamResultDetail(this.$route.query.examInfoId, this.$route.query.employeeId)
    }
  },
  mounted () {
  },
  methods: {
    // 打印页面
    _print () {
      printPage(`${this.basicInfo.employeeName}-${this.basicInfo.examName}试卷详情`, 'dynamic-table-page')
    },
    // 获取当前考试详情
    _getExamResultDetail (examInfoId, employeeId) {
      this.allTopicListCopy = []
      getStudentExamDetailApi({ id: examInfoId, testerId: employeeId }).then((res) => {
        if (res.code === 200) {
          this.basicInfo = res.data.basicInfo
          this.percentageScore = (Number(this.basicInfo.testerTotalScore) / Number(this.basicInfo.sumScore)) * 100
          this.questionsGroupList = JSON.parse(JSON.stringify(res.data.questionInfos))
          if (this.questionsGroupList?.length !== 0) {
            this.questionsGroupList.forEach((groupInfo, groupIndex) => {
              this._getNeedShowData(this.questionsGroupList, groupInfo, groupIndex)// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
            })
          }
          this.questionsGroupList.forEach((grouptopicList) => {
            this.allTopicListCopy.push.apply(this.allTopicListCopy, grouptopicList.questionInfo)
          })
        }
      })
    },
    // 试卷-- 回显所需数据格式
    _getNeedShowData (groupTopicList, groupInfo, groupIndex) {
      groupInfo.groupIndex = groupIndex
      groupTopicList[groupIndex].questionInfo = groupTopicList[groupIndex].questionInfo.map((topicItem, topicIndex) => {
        return {
          ...topicItem,
          index: topicIndex,
          groupIndex: groupIndex,
          fillingSign: topicItem.title ? (topicItem.title.match(/_/g) || []).length : 0,
          fillAnswer: topicItem.testerAnswer ? topicItem.testerAnswer.split('。').map((item) => { return { answer: item } }) : [],
          answerCheck: topicItem.answer ? topicItem?.answer?.split(',') : [],
          testerAnswerCheck: topicItem.testerAnswer ? topicItem?.testerAnswer?.split(',') : [],
          options: topicItem.options ? JSON.parse(topicItem?.options) : [],
          contentUrl: topicItem.contentUrl ? topicItem?.contentUrl?.split(',') : [],
          analysisUrl: topicItem.analysisUrl ? topicItem?.analysisUrl.split(',') : [],
          mark: topicItem.testerMark,
          correctStatus: topicItem.correctStatus
        }
      })
    },
    // 输入分数--打分
    _inputTopicMark (item) {
      // 限制打分最大值和最小值-start
      if (item.mark !== undefined && item.mark !== null && item.mark !== '') {
        if (Number(item.mark) > Number(item.score)) {
          item.mark = Number(item.score)
        }
        if (Number(item.mark) < 0) {
          item.mark = 0
        }
      }
      // 限制打分最大值和最小值-end
      // 获取所有题目--列表
      const allTopicList = []
      const questionsGroupListCopy = JSON.parse(JSON.stringify(this.questionsGroupList))
      questionsGroupListCopy.forEach((grouptopicList) => {
        allTopicList.push.apply(allTopicList, grouptopicList.questionInfo)
      })
      // 计算批阅正确、批阅错误、未批阅、题目数量
      let correctTopicNum = 0; let errorTopicNum = 0; let unCheckNum = 0; let studentScore = 0
      allTopicList.forEach((topicItem, topicIndex) => {
        // 计算学生分数
        studentScore += Number(topicItem.mark || 0)
        // 正确题目数量
        if (Number(topicItem.mark || 0) === Number(topicItem.score || 0)) {
          correctTopicNum++
          topicItem.correctStatus = 1
        } else {
          topicItem.correctStatus = 2
        }
        // 错误题目数量
        if (topicItem.mark !== null && topicItem.mark !== '' && topicItem.mark !== undefined && Number(topicItem.mark || 0) < Number(topicItem.score || 0)) {
          errorTopicNum++
        }
        // 未批阅题目数量
        if (topicItem.mark === null || topicItem.mark === '' || topicItem.mark === undefined) {
          unCheckNum++
        }
      })
      this.basicInfo.trueNUm = correctTopicNum
      this.basicInfo.wrongNum = errorTopicNum
      this.basicInfo.reviewedNum = Number(correctTopicNum) + Number(errorTopicNum)
      this.basicInfo.unratedNum = unCheckNum
      this.basicInfo.testerTotalScore = studentScore
      this.percentageScore = (Number(this.basicInfo.testerTotalScore) / Number(this.basicInfo.sumScore)) * 100
      this.allTopicListCopy = allTopicList
    },
    // 题目类型
    getTypeValue (type) {
      let value
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          value = '单项选择'
          break
        case 'MULTIPLE_CHOICE_TYPE':
          value = '多项选择'
          break
        case 'JUDGMENT_TYPE':
          value = '判断题'
          break
        case 'GAP_FILLING_TYPE':
          value = '填空题'
          break
        case 'ESSAY_QUESTION_TYPE':
          value = '问答题'
          break
        default:
          break
      }
      return value
    },
    // 快速定位
    _quickPositionBtn (questionIndex, topicIndex) {
      const dom = document.getElementById(`topicLink${questionIndex}${topicIndex}`)
      dom.scrollIntoView(true)
      const scrolledY = window.scrollY
      if (scrolledY) {
        window.scroll(0, scrolledY - 60) // 防止顶部有悬浮窗
      }
    },
    // 提交--批阅试卷
    _submitCheckPaperBtn () {
      if (this.basicInfo.unratedNum) {
        return this.$message.error('还有未批阅的题目，请批阅！')
      }
      const paramms = {
        examInfoId: this.$route.query.examInfoId, // 考试id
        testerId: this.$route.query.employeeId, // 考生id
        testerName: this.basicInfo.examer, // 考生姓名
        markExams: this.allTopicListCopy.map((item) => {
          return {
            questionId: item.id,
            mark: item.mark,
            correctStatus: item.correctStatus
          }
        }) // 批阅列表
      }
      // basicInfo.unratedNum 未批阅数量
      getExamGradingApi({ ...paramms }).then((res) => {
        if (res.code === 200) {
          this.$router.push({
            path: '/examArrangeDetail',
            query: {
              examId: this.$route.query.examInfoId
            }
          })
        }
      })
    },
    _backBtn () {
      this.$router.push({
        path: '/examArrangeDetail',
        query: {
          examId: this.$route.query.examInfoId
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style.scss';
/deep/.el-card__body, .el-main{
  padding: 0px!important;
}
// /deep/.el-card{
//   height: 88vh;
// }
.input-box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  span {
    display: inline-block;
    margin-right: 15px;
    width: 50px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
  }
}
  // 左侧快速定位
  .quick-position{
    margin-top: 12px;
    .quick-position-content{
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .position-group-title{
        color: rgba(0,0,0,0.6);
        margin-bottom: 4px;
      }
      .position-group-content{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        #position-number{
          width: 39px;
          height: 26px;
          margin-right:8px ;
          border-radius: 4px;
          margin-bottom: 8px;
          text-align: center;
          line-height: 26px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
// 试卷信息
.paper-right{
  width: 80%;
  height: calc(100vh - 240px)!important;
}
#dynamic-table-page{
  padding: 24px;
}
.printable-content {
  /* 定义内容的样式 */
  height:100%;
}
.page-top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
}
</style>
