
/*
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2024-06-14 15:16:09
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-14 15:21:13
 */
// 实现页面打印（保留页面原有样式，传参(文件名,id选择器)）
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
export function printPage (fileName, idName) {
  var dom = document.querySelector(`#${idName}`)
  const opt = {
    allowTaint: true,
    dpi: window.devicePixelRatio, // 提升导出文件的分辨率
    scale: 2, // 提升导出文件的分辨率
    backgroundColor: 'white',
    useCORS: true,
    margin: 300,
    height: dom.scrollHeight,
    windowWidth: dom.offsetWidth + 400
  }
  html2canvas(document.getElementById(`${idName}`), opt).then(
    (canvas) => {
      var pdf = new jsPDF('p', 'mm', 'a4') // A4纸，纵向
      var ctx = canvas.getContext('2d')
      var a4w = 190; var a4h = 277 // A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
      var imgHeight = Math.floor(a4h * canvas.width / a4w) // 按A4显示比例换算一页图像的像素高度
      var renderedHeight = 0
      while (renderedHeight < canvas.height) {
        var page = document.createElement('canvas')
        page.width = canvas.width
        page.height = Math.min(imgHeight, canvas.height - renderedHeight)// 可能内容不足一页
        // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width)) // 添加图像到页面，保留10mm边距
        renderedHeight += imgHeight
        if (renderedHeight < canvas.height) { pdf.addPage() }// 如果后面还有内容，添加一个空页
      }
      pdf.save(fileName + '.pdf')
    }
  )
}
